import apple from './apple.svg'
import google from './google.svg'
import appicon from './appicon_raw.svg'
import scan from './ScanOutlined.svg'

import './Home.css';

function Index() {
  return (
    <div className="Home">
      <banner>
        <div className='Home-banner'>
          <div className='logo_container'>

            <div className='title'>
              Please scan the QR Code with BIEASES App
            </div>
            <div>
              <img className='logo' src={appicon}></img>
            </div>
          </div>
        </div>
      </banner>
      <div className='Home-container'>
        <div className='title'>
          Tab below ICON to download BIEASES App
        </div>
        <div className='store_container'>
          <div className='app_store'>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={google} alt="logo" />
            </a>
          </div>
          <div className="app_store">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={apple} alt="logo" />
            </a>
          </div>
        </div>
        <div className='instruction'>
          <div className='title'>
            Already installed BIEASES App?
          </div>
          <div className='content'>
            Tap the "<strong>Scan    <img src={scan} /> </strong>" button to scan the QR code again to view and place orders
          </div>

        </div>
        <div className='content'>
        © {new Date().getFullYear()} BIEASES. All rights reserved.
        </div>
      </div>

    </div>
  );
}

export default Index;
